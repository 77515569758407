import React, { type RefObject, createRef, useEffect, useRef } from 'react'
import AuthLayout from './AuthLayout'
import { AuthWrapper } from '../style'
import { Form, InputRef } from 'antd'
import CustomButton from 'components/CustomButton'
import { InputItem } from 'components/commonCustom'
import Captcha from './capcha'
import { loginApi, meApi } from 'apis/auth'
import { saveLocalStorage } from 'utils/localStorage'
import { useDispatch, useSelector } from 'react-redux'
import types from 'store/actions/types'

type Refs = {
  id: RefObject<InputRef>
  pass: RefObject<InputRef>
  myCaptcha: RefObject<InputRef>
}
const LoginModal = () => {
  const captcha = useRef('')
  const dispatch = useDispatch()
  const popupStates = useSelector((state: any) => state.waitting)
  const block = useRef(false)
  const refs = useRef<Refs>({
    id: createRef(),
    pass: createRef(),
    myCaptcha: createRef()
  })

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown)
    return () => {
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [])

  const onKeyDown = (e: any) => {
    if (block.current) return
    if (e.code !== 'Enter') return
    handleSubmit()
  }

  const handleSubmit = async () => {
    const { id, pass, myCaptcha } = refs.current
    block.current = true
    if (!id.current?.input?.value) return alert('아이디는 비워두면 안 됩니다')
    if (!pass.current?.input?.value)
      return alert('비밀번호는 비워두면 안 됩니다')
    if (!myCaptcha.current?.input?.value)
      return alert('캡차는 비워두면 안 됩니다')

    if (myCaptcha.current.input.value !== captcha.current)
      return alert('캡차는 틀렸습니다.')

    try {
      dispatch({ type: 'WAITTING' })
      const res: any = await loginApi({
        mb_id: id.current?.input?.value,
        mb_pass: pass.current?.input?.value
      })
      // console.log(res);
      if (res.result) {
        saveLocalStorage({
          name: 'User',
          data: res?.access_token
        })
        const resMe: any = await meApi()
        if (resMe.result === false) {
          alert(resMe.message)
          dispatch({ type: 'STOP_WAITTING' })
        } else {
          dispatch({ type: 'STOP_WAITTING' })
          dispatch({
            type: types.GET_DATA_USER,
            dataUser: resMe.data
          })
        }
        dispatch({ type: types.CLOSE_MODAL })
        dispatch({ type: 'STOP_WAITTING' })
      } else {
        alert(res?.error.message)
        dispatch({ type: 'STOP_WAITTING' })
      }
    } catch (error) {
      console.log(error)
    } finally {
      dispatch({ type: 'STOP_WAITTING' })
      block.current = false
    }
  }

  return (
    <AuthLayout>
      <AuthWrapper>
        <div className='title'>로그인</div>
        <div className='form'>
          <div className='form-item'>
            <label>아이디*</label>
            <InputItem ref={refs.current.id} placeholder='아이디*' />
          </div>
          <div className='form-item'>
            <label>비밀번호*</label>
            <InputItem
              ref={refs.current.pass}
              type='password'
              placeholder='비밀번호*'
            />
          </div>
          <div className='form-item' style={{ marginTop: '19px', gap: 0 }}>
            <Captcha onClick={(e: string) => (captcha.current = e)} />
            <InputItem
              ref={refs.current.myCaptcha}
              placeholder='이미지 클릭시 새로운 이미지 나옴'
            />
          </div>
          <div className='btn-gr'>
            <CustomButton
              disabled={popupStates.isWaitting}
              onClick={handleSubmit}
            >
              로그인
            </CustomButton>
          </div>
        </div>
        {/* <h6>이미지 클릭시 새로운 이미지 나옴</h6> */}
      </AuthWrapper>
    </AuthLayout>
  )
}

export default LoginModal
