import styled from 'styled-components'
import { Modal, Input } from 'antd';

export const CustomModal = styled(Modal)`
  .ant-modal-content{
    background-color: transparent;
    padding: 0;
    position: relative;
  } 
  .ant-modal{
    width: 100% !important;
  }
`

export const AuthModal = styled.div`
  border-radius: 10px;
  border: 2px solid var(--Gold-stroke, #FEEAC3);
  background: rgba(16, 16, 16, 0.50);
  backdrop-filter: blur(7px);
  .close-icon{
    position: absolute;
    right: calc(0% - 25px);
    top: calc(0% - 35px);
    width: 50px;
    height: 50px;
    cursor: pointer;
    img{
      width: 100%
    }
    @media screen and (max-width: 768px) {
      width: 35px;
      right: calc(0% - 18px);
      top: calc(0% - 20px);
    }
  }
  .title{
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: var(--Button-hover, linear-gradient(180deg, #F00 0%, #FFD02B 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media screen and (max-width: 768px) {
      font-size: 20px;
    }
  }
`
export const AuthWrapper = styled.div`
  padding: 30px 30px 49px;
  width: 40vw;

  @media screen and (max-width: 768px) {
    padding: 25px 25px 37px;
    width: 80vw;
  }

  &.register, &.qna{
    width: 80vw;
    padding: 31px 60px 37px;
    @media screen and (max-width: 768px) {
      padding: 25px 25px 30px;
    }
  }
  h6{
    background: var(--Gold-stroke, linear-gradient(180deg, #FEEAC3 0%, #FFE6A6 29.17%, #FFB800 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 26px;
    text-align: center;
    @media screen and (max-width: 768px) {
      font-size: 12px;
      margin-top: 20px;
    }
  }
  form, .form{
    margin-top: 19px;
    display: flex;
    flex-direction: column;
    gap: 19px;
    &.qna{
      .rounded-btn{
        max-width: 110px;
        margin-left: 0 !important;
      }
    }
    &.register{
      display: block;
      .layout-form{
        display: flex;
        width: 100%;
        gap: 54px;
        @media screen and (max-width: 768px) {
          flex-direction: column;
          gap: 10px;
          margin-bottom: 20px;
        }
        .form-column{
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
      }
    }
    .btn-gr{
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 768px) {
        margin-top: 0px;
      }
    }
    .ant-form-item{
      margin-bottom: 0;
      width: 100%;
    }
    .form-item{
      label{
        color: #fff;
        width: 100%;
        max-width: 105px;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        @media screen and (max-width: 768px) {
          font-size: 12px;
          max-width: 60px;
        }
      }
      span{
        font-size: 20px;
        font-weight: 700;
        background: var(--Button-hover, linear-gradient(180deg, #F00 0%, #FFD02B 100%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .rounded-btn{
        max-width: 110px;
        width: 100%;
        margin-left: 5px;
        @media screen and (max-width: 768px) {
          max-width: 70px;
          margin-left: 0;

        }
      }
      &.column-mobile{
        @media screen and (max-width: 768px) {
          flex-direction: column;
        }
      }
      textarea{
        border: 1px solid var(--Gold-stroke, #FEEAC3);
        background: #101010;
        border-radius: 0;
        color: #fff;
        font-size: 20px;
        font-weight: 700;
        height: 15em;
        @media screen and (max-width: 768px) {
          height: 10em;
        }
        &::placeholder {
          font-weight: bold;
          opacity: 0.5;
          color: #fff;
          font-size: 20px;
          font-weight: 700;
          @media (max-width: 768px) {
            font-size: 12px;
          }
        }
      }
      display: flex;
      width: 100%;
      align-items: center;
      gap: 5px;
    }
  }
`

